.job-card-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
  }
  
  .job-card-table th,
  .job-card-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .job-card-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .job-card-table tr {
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .job-card-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .job-card-table tr:active {
    background-color: #e6e6e6;
  }
  
  @media screen and (max-width: 600px) {
    .job-card-table {
      font-size: 12px;
    }
  
    .job-card-table th,
    .job-card-table td {
      padding: 8px;
    }
  
    .job-card-table th {
      font-size: 14px;
    }
  }


  .selected-job-card {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .selected-job-card h3 {
    margin-top: 0;
  }
  
  .selected-job-card p {
    margin-bottom: 10px;
  }
  
  .selected-job-card ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 20px;
  }
  
  .generate-invoice-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .generate-invoice-btn:hover {
    background-color: #45a049;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    outline: none;
    max-width: 600px;
    width: 100%;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .close-modal-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ccc;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .close-modal-btn:hover {
    background-color: #999;
  }